import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [2,4,5];

export const dictionary = {
		"/": [~7],
		"/(protected)/0/acquisitions": [8,[2],[3]],
		"/(protected)/0/acquisitions/referrals": [~9,[2],[3]],
		"/(protected)/0/acquisitions/referrals/create": [~10,[2],[3]],
		"/(protected)/0/home": [11,[2],[3]],
		"/(protected)/0/inbox": [~12,[2],[3]],
		"/(protected)/0/marketing/qrCodes": [13,[2,4],[3]],
		"/(protected)/0/marketing/qrCodes/[id]": [~14,[2,4],[3]],
		"/(protected)/0/sales/offers": [15,[2,5],[3]],
		"/(protected)/0/sales/offers/[id]": [~16,[2,5],[3]],
		"/(protected)/0/team/accountabilityChart": [~17,[2],[3]],
		"/(protected)/0/team/acnew": [~18,[2],[3]],
		"/(protected)/0/team/callins/create": [~19,[2],[3]],
		"/(protected)/0/team/shoutouts": [~20,[2],[3]],
		"/(protected)/0/team/suggestions/create": [~21,[2],[3]],
		"/(protected)/0/tools/mortgage-calculator": [22,[2],[3]],
		"/auth/login": [~23,[],[6]],
		"/auth/login/verifyCookies": [24,[],[6]],
		"/auth/logout": [~25,[],[6]],
		"/domain.com": [26],
		"/drop/admin": [~28],
		"/drop/[linkId]": [~27],
		"/listings": [~29],
		"/offer.fergmar.com": [30],
		"/privacy-policy": [31],
		"/testRoute2": [33],
		"/testRoute": [32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';